import { useEffect, useState } from "react";
import styles from "./programs.module.css";
import style from "../../Coachee/CSS/dashboardHome.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CreateLiveSession, fetchCoachProgramByID, FetchProgramsCoach, FetchSessions } from "../../../../Api";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { cdn_Link } from "../../../../config";
import { Box, Modal, TextField, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import dayjs from "dayjs";
import { DateTime } from 'luxon';
import { notifyError, notifySuccess } from "../../../../Notify";
import FastRewindIcon from '@mui/icons-material/FastRewind';
type propType = {
  setActiveTab: (val: number) => void;
  pid: any;
  coacheeData: any;
};

const ProgramById = ({ setActiveTab, pid, coacheeData }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [progSession, setProgSession] = useState<any>([]);
  const [coacheeId, setCoacheeId] = useState('');
  const [open, setOpen] = useState(false);
  const[sessiondate,setSessionDate]=useState('')
  const[sessionST,setSessionST]=useState('')
  const[sessionET,setSessionET]=useState('')
  const[updata,setUpdata]=useState(false)
  const [selectedCard, setSelectedCard] = useState('');
  const [programData, setProgramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
const fetchSession=()=>{
  FetchSessions({ filter:1, search:data.search, cookie: cookie, page: data.page })
  .then((result: any) => {
    let res = result.data;
    let x: any = { ...data };
    x = {
      ...x,
      next: res.next,
      prev: res.prev,
      total_page: res.total_page || 1,
      history: [...res.history],
    };

    setData(x);
  })
  .catch((err) => {});
}
  const fetchSome = () => {
    
    fetchCoachProgramByID(pid, cookie)
      .then((res: any) => {
        setProgSession(res.data.history);
       
      })
      .catch((error: any) => {
        //console.log(error);
      });
  };

  const fetchProgram = () => {
    FetchProgramsCoach({
      cookie,
      page: programData.page,
      search: programData.search,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...programData };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setProgramData(x);
      })
      .catch((error: any) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    fetchProgram();
    fetchSome();
    fetchSession();
  }, [cookie,updata,data.page,programData.page]);

 
  const handleCardVal = (event: React.ChangeEvent<HTMLSelectElement>) => {
   
    const selectedValue = event.target.value;
    setSelectedCard(selectedValue); 
    //console.log("Selected Coachee ID:", selectedValue);
  };
  const createSession=(e:any)=>{
    setUpdata(true)
    e.preventDefault();
  // coacheeId: any, pId: any, starttime: any, endtime: any, date: any, cookie: string
 const date=dayjs(sessiondate).format('MM-DD-YYYY')
//  const starttime=dayjs(sessionST).format('h:mm A')
//  const endtime=formatTime(sessionET)
 const startTime = DateTime.fromFormat(sessionST, 'HH:mm');
 const starttime=startTime.toFormat('hh:mm a');
 const endTime = DateTime.fromFormat(sessionET, 'HH:mm');
 const endtime=endTime.toFormat('hh:mm a');
 //console.log("This is worst  time",sessionET,startTime)
//  starttime: any, endtime: any, date: any
    if(selectedCard && sessiondate && sessionST  && sessionET){
      CreateLiveSession(selectedCard,pid,starttime,endtime,date,cookie)
      .then((res:any)=>{
        notifySuccess(res.message || "session is created")
        setUpdata(false)
        setOpen(false)
      })
      .catch((err:any)=>{
        notifyError(err.response.data.message)
        setUpdata(false)
        setOpen(false)
      })

    }
  }

  return (
    <>
      <div className={styles.programsDisplay}>
      <FastRewindIcon style={{cursor:'pointer',color:'#0C5899'}} onClick={()=>setActiveTab(0)}/>
        {programData.history.length > 0 ? (
          programData.history.map((progdata: any) => {
            return progdata._id === pid ? (
              <>
                <div className={styles.programHead}>
                  <img
                    src={`${cdn_Link}/${progdata.programImg}`}
                    className={styles.progImg}
                  />
                  <div>
                    <h6>{progdata.name}</h6>
                    <p>
                      <AccessAlarmsIcon />{" "}
                      {dayjs(progdata.createdAt.slice(0, 10)).format(
                        "DD MMM YYYY"
                      )}
                    </p>
                  </div>
                </div>

                <div className={styles.ProgDesc}>
                  <br />
                  <br />
                  <h6>Description</h6>
                  <p>{progdata.description}</p>
                </div>

                <div className={styles.totalSess}>
                  <div className={styles.totalSessHead}>
                    <h6>Total Session</h6>
                    <button
                      className={styles.totalSessHeadBtn}
                      onClick={() => setOpen(true)}
                    >
                      <LibraryAddIcon /> Create Sessions
                    </button>

                    <Modal
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className={style.BoxStyle}>
                        <span
                          className={style.HandleClose}
                          onClick={() => setOpen(false)}
                        >
                          <CloseTwoToneIcon className={style.HandleCloseIcon} />
                        </span>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                          Create New Session
                        </Typography>
                        <Typography id="modal-modal-description" className={style.typoDesc}>
                          <div className={style.typesjhsd}>
                          {/* <TextField
                            type="text"
                            label="Session Topic"
                            className={style.Inputfieldsssds}
                            InputProps={{
                              className: style.muiInput,
                            }}
                            sx={{
                              my: 1,
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          /> */}
                          <select
                            className={style.cardDesignSelect1}
                            onChange={handleCardVal}
                            value={selectedCard}
                          >
                            <option>
                              Select Coachee 
                            </option>
                            {coacheeData.length > 0 ? (
                              coacheeData.map((cdata: any) => (
                                cdata._id === pid
                                  ? cdata.applied?.map((pdata: any) => (
                                      <option
                                        key={pdata.coacheeDetails?._id}
                                        value={pdata.coacheeDetails?._id}
                                        className={style.selectOption}
                                        
                                      >
                                       <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                      <img src={`${cdn_Link}/${pdata.coacheeDetails?.profileImg}`} style={{width:'50px',height:'50px',borderRadius:'50%'}}/>
                                      <div>
                                       <p>{pdata.coacheeDetails?.name}</p>
                                      </div>
                                       </div>
                                      </option>
                                    
                                    ))
                                  : null
                              ))
                            ) : (
                              <option disabled>No coachee data available</option>
                            )}
                          </select>
                          <TextField
                            type="date"
                            label="Session Date"
                            className={style.Inputfieldsssds}
                            InputProps={{
                              className: style.muiInput,
                            }}
                           
                          
                            value={sessiondate}
                            onChange={(e)=>{setSessionDate(e.target.value)}}
                            sx={{
                              my: 1,
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <TextField
                            type="time"
                            label="Session Start Time"
                            className={style.Inputfieldsssds}
                            InputProps={{
                              className: style.muiInput,
                            }}
                           
                            // const[sessionET,setSessionET]=useState('')
                            value={sessionST}
                            onChange={(e)=>{setSessionST(e.target.value)}}
                            sx={{
                              my: 1,
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <TextField
                            type="time"
                            label="Session End time"
                            className={style.Inputfieldsssds}
                            InputProps={{
                              className: style.muiInput,
                            }}
                            
                               value={sessionET}
                               onChange={(e)=>{setSessionET(e.target.value)}}
                            sx={{
                              my: 1,
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <button className={style.TypoButton} onClick={(e:any)=>{createSession(e)}}>Save </button>
                          <button  onClick={()=>setOpen(false)} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} > 
                             Cancel</button>

</div>
                        </Typography>
                      </Box>
                    </Modal>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        <th>SESSION TOPIC</th>
                        <th>WHAT ARE WE GOING TO LEARN</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {progSession.length > 0 ? (
                        progSession.map((session: any) => (
                          <tr key={session._id}>
                            <td className={styles.groupIcTd}>
                              <span className={styles.groupIc}>
                                {session.title.substring(0, 2).toUpperCase()}
                              </span>{" "}
                              {session.title.slice(0, 15)}
                            </td>
                            <td>{session.expected.slice(0, 30)}</td>
                            <td>
                              {dayjs(session.updatedAt.slice(0, 10)).format(
                                "DD MMM YYYY"
                              )}
                            </td>
                            <td>
                              {session.isCompleted === false ? (
                                <p className={styles.groupUpcome}>
                                  <AccessTimeIcon /> Upcoming
                                </p>
                              ) : (
                                <p className={styles.groupComplete}>
                                  <TaskAltIcon /> Completed
                                </p>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No data Found!</td>
                        </tr>
                      )}
                       {data.history.length > 0 ? (
                        data.history.map((session: any) => (
                       
                      session._id.pId === pid ? (session.sessions.length>0?(session.sessions.map((session:any,index:number)=>(
                       <tr>

                         <td className={styles.groupIcTd}><span className={styles.groupIc}>
                                LS
                              </span>Live Session {index+1}</td>
                              <td>-</td>
                              <td>{dayjs(session.date).format('DD MMM YYYY')}</td>
                              <td>{session.starttime} - {session.endtime}</td>
                              <td > <a href={session.join_url} target="_blank" rel="noopener noreferrer"> Join Now</a></td>
                       </tr>
                      ))):''):('')
                     
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No data Found!</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null;
          })
        ) : (
          <p>No data Found</p>
        )}
      </div>
    </>
  );
};

export default ProgramById;
