import { TextField } from "@mui/material";
import styles from "../setup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CoacheePersonalInfo } from "../../../Api";
import { setProfileSetup } from "../../../redux/userSlice";
import { UserType } from "../../../@types/user";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { notifyError } from "../../../Notify";

type PropType = {
  setCActiveTab: (val: (v: number) => number) => void;
};

const CoacheeTab1 = ({ setCActiveTab }: PropType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [previewImage, setPreviewImage] = useState("");
  const dispatch = useDispatch();
  const[check,setCheck]=useState(false)
  

  

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mono: "",
      streetName: "",
      pinCode:"",
      city: "",
      state: "",
      profile: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required")
        .matches(/^[A-Za-z]+$/, "First Name must contain only letters"),
      lastName: Yup.string().required("Last Name is required")
        .matches(/^[A-Za-z]+$/, "Last Name must contain only letters"),
      mono: Yup.string()
        .required("Contact is required")
        .matches(/^[0-9]+$/, "Contact must be a valid number")
        .min(10, "Contact must be at least 10 digits")
        .max(15, "Contact must be at most 15 digits"),
      streetName: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
    }),
    
    onSubmit: (values: any) => {
      setCheck(true)
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("mono", values.mono);
      formData.append("location", JSON.stringify({
        streetName: values.streetName,
        city: values.city,
        state: values.state,
        pinCode:values.pinCode
      }));
     console.log("profile of user",values.profile);
     
      formData.append("profile", values.profile);
      CoacheePersonalInfo(formData, cookie)
      .then((result: any) => {
        //console.log(result);
        setCheck(true)
        const updatedUser: UserType = {
          ...result.data.user,
          token: result.data.token,
          
        };
        // dispatch(setProfileSetup(updatedUser)); 
        setCActiveTab((prev) => prev + 1);
          setCheck(false)

        })
        .catch((error) => {
          console.error(error);
          setCheck(false)
        });
    },
  });

  const handleProfileClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      formik.setFieldValue("profile", file);
      const imageURL = URL.createObjectURL(file);
      setPreviewImage(imageURL);
    }
  };
useEffect(()=>{
  if (!previewImage) {
      
    notifyError('Profile image is required. Please upload a profile image.');
    return; 
  }
},[check])
  return (
    <div className={styles.FormContainer}>
      <h3>Personal Info</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.InputContainer}>
          <TextField
            type="text"
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 1, my: 1, borderRadius: "20px" }}
            required
          />
          <TextField
            type="text"
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 1, my: 1, borderRadius: "20px" }}
            required
          />
        </div>
        <div className={styles.CoacheeFormContent}>
          <TextField
            type="text"
            label="Contact No"
            variant="outlined"
            name="mono"
            value={formik.values.mono}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.mono && Boolean(formik.errors.mono)}
            helperText={formik.touched.mono && formik.errors.mono}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 1, my: 1, borderRadius: "20px" }}
            required
          />
           <div className={styles.InputContainer}>
          <TextField
            type="text"
            label="Street Name"
            variant="outlined"
            name="streetName"
            value={formik.values.streetName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.streetName && Boolean(formik.errors.streetName)}
            helperText={formik.touched.streetName && formik.errors.streetName}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 2, my: 1, borderRadius: "20px" }}
            required
          />
           <TextField
            type="text"
            label="PIN Code"
            variant="outlined"
            name="pinCode"
            value={formik.values.pinCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
            helperText={formik.touched.pinCode && formik.errors.pinCode}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 2, my: 1, borderRadius: "20px" }}
            required
          />
          </div>
          
        </div>
        <div className={styles.InputContainer}>
          <TextField
            type="text"
            label="City"
            variant="outlined"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 1, my: 1, borderRadius: "20px" }}
            required
          />
          <TextField
            type="text"
            label="State"
            variant="outlined"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
            className={styles.Inputfield}
            InputProps={{ className: `${styles.muiInput}` }}
            sx={{ mr: 1, my: 1, borderRadius: "20px" }}
            required
          />
        </div>
        <div className={styles.ProfileImage}>
          <input
            type="file"
            id="fileInput"
            name="profile"
            onChange={handleProfileChange}
            accept="image/*"
            style={{ display: "none" }}
            required
          />
          <div className={styles.MainImg} onClick={handleProfileClick}>
            <img
              src={previewImage ? previewImage : "/images/Coach/NonePorfile.jpg"}
              alt="Preview"
              className={styles.MainImgPre}
            />
            <div className={styles.IconImg}>
              <img
                src="/images/svg/Coach/group-8291.svg"
                alt="Upload Icon"
              />
            </div>
          </div>
        </div>
        <div className={styles.textcenter1}>
          <button className={styles.button4} type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CoacheeTab1;
