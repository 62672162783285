import { useEffect, useState } from "react";
import { CreateSessionByCoachee, fetchCard, fetchCoachByCoachee, fetchCoachDataForCoachee, fetchCoachprogramByID } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";
import EmailIcon from '@mui/icons-material/Email';
import styles from "../CSS/dashboardHome.module.css";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Box, Button, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { PaymentModal } from "../../../modal/PaymentModal";
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CircularProgress from '@mui/material/CircularProgress';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import dayjs from "dayjs";
import { cdn_Link } from "../../../../config";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachId: any;

};
const ShowCoach = ({ setActiveTab, coachId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [comPow, setcomPow] = useState<string[]>([])
  const [cardId, setCardId] = useState("")
 
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<any[]>([]);
  const [Typo, setTypo] = useState(0)
  const[programData,setProgramData]=useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true); 
  const [appDetails, setAppDetails] = useState({
    "coachId": "",
    "pId": "",
    "gId": "",
    "starttime": "",
    "endtime": "",
    "appType": "",
    "date": "",
  })
  const [data, setData] = useState<any>([])
useEffect(()=>{
  fetchCoachprogramByID(coachId,cookie)
  .then((res:any)=>{
    setProgramData(res)
  })
},[cookie])
  const handleOpen = () => {
    (programData.length)?
      setOpen1(true):
      notifyError("Please buy Program Then Continue")
    }
      
  // const handleClose = () => ;
  useEffect(() => {
    fetchCard(cookie)
      .then((result: any) => {
        setCards(result.data)
      })
      .catch((error: any) => {
        //console.log("from Card", error)
      })
  }, [cookie])

  const handleCloseModal = () => {
    setOpen1(false)
    setTypo(0)
  }
  const handleVideoLoadedData = () => {
    setIsLoading(false); 
  };
  const handleCardVal = (event: any) => {
    setCardId(event.target.value)
  }

  useEffect(() => {
    fetchCoachDataForCoachee(cookie, coachId)
      .then((result: any) => {
        setData(result.data)
        setcomPow(result.data.coach.commpower)
        setLoading(false)
       
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie, coachId])
  // CreateSessionByCoachee
  const appType = 1;

  // useEffect(() => {
  //   fetchCoachByCoachee(cookie)
  //     .then((result: any) => {
  //       // setMon(result.data.ava)
  //       // seCoachData(result.data.history)


  //     })
  //     .catch((error: any) => {
  //       console.error("Error fetching coach data:", error);
  //       notifyError(error);
  //     });
  // }, [cookie]);

  const handleSubmit = (e: any) => {
    e.preventDefault()
if (!appDetails.pId ){
  notifyError("Select One Program!")
}
else{
  CreateSessionByCoachee(coachId, appDetails.starttime,appDetails.endtime, appType, appDetails.date, appDetails.pId, cookie)
  .then((result) => {
    notifySuccess(`Congrates! Your session is planned !`)
    handleCloseModal()
    
  }).catch((err: any) => {
    notifyError(err.response.data.message)
    handleCloseModal()
    setTypo(0)
  })
}
   

  }

  return (
    <div className={styles.dashcontainer}>
      <div className={styles.UpcomingContainer}>
    <FastRewindIcon style={{color:'#174C8E',cursor:'pointer'}} onClick={()=>setActiveTab(()=>0)}/>
        <div className={styles.recommendContainer}>


          <div className={styles.recommendLeft}>
            {data?.coach?.uid?.profileImg ? (<img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach?.uid?.profileImg}`}
              className={styles.coachImg}
            />) : (
              <h4 className={styles.coachIcon}>{data?.coach?.uid?.name?.slice(0, 2).toUpperCase()}</h4>
            )}

            <span>
              <h5>{data.coach?.uid?.name || 'User'}</h5>
              <p className={styles.dataIcoCoach}><span><EmailIcon /></span> {data.coach?.uid?.email}</p>
            </span>
          </div>
          <div className={styles.recommendRight}>
            
            <button className={styles.TypoButton}  onClick={handleOpen}><span><EditCalendarIcon /></span>Schedule Appointment</button>

            <Modal
              open={open1}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleCloseModal} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                <h5> Schedule Appointment</h5>

                </Typography>

                <PaymentModal data={data} coachId={coachId} comPow={comPow} setTypo={setTypo}  setAppDetails={setAppDetails} programData={programData}/>
                <button onClick={(e:any) =>handleSubmit(e)} className={styles.TypoButton}>Save</button>

              


              </Box>
            </Modal>
          </div>

        </div>

        <hr />
        <div style={{display:'flex',flexDirection:'column'}}>
          <b>About Coach</b>
          <p>Social Media Links</p>
    
          <p style={{color:'#174C8E',fontSize:'13px'}}><FacebookIcon  className={styles.onlineProic1}/> {data?.coach?.instagramLink} </p>
          <p style={{color:'#174C8E',fontSize:'13px'}}><InstagramIcon  className={styles.onlineProic2}/> {data?.coach?.facebookLink} </p>
          <p style={{color:'#174C8E',fontSize:'13px'}}><LinkedInIcon  className={styles.onlineProic1}/>{data?.coach?.twitterLink} </p>
          
        
          <video src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach?.introvideo}`} style={{maxWidth:'400px',borderRadius:'20px'}} autoPlay loop />
        
        </div>

        <div>
          <br />
    
       <b>Categories that can help</b>

          <div className={styles.commPoweStyleContent}>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
            
            <p className={styles.comPowStyle}>
              {compow.split(',').map((part: string, i: number) => (
                <p key={i} className={styles.comPowStyles}>
                  {part.trim()}
                </p>
                
              ))}
            </p>
          </>))) : (<>No Data found!</>)}</div>
          <br/>
          <h6><b>Entitled With Programs</b></h6>
       <div className={styles.DashForRecom}>
       
       <div className={styles.Recommond}>
          {programData.length>0?(programData.map((program:any)=>(

            <>
               <div className={styles.coaching}>
               <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${program.programDetails?.programImg}`}
                        className={styles.coachingImg}
                      />
                      <div className={styles.coachingTxt} >
                      <p>{program.programDetails?.name}</p>
                      <span>{program.programDetails?.description.slice(0,60)}..</span>
                      </div>
               </div>
            </>
          ))):(<>Please Select Programs</>)}

</div>
</div>
          
        </div>
        <div>
  
        </div>

      </div>

    </div>
  )

}
export default ShowCoach;