import style from "./ChatCoach.module.css";
import { useEffect, useState, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import {
  GetAllMsg,
  SendMsg,
  fetchCoachByCoachee,
  GetCoachMsg,
} from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError } from "../../../../Notify";
import dayjs from "dayjs";

const ChatCoach = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState([]);
  const [chatData, setChatData] = useState<any>([]);
  const [chatId, setChatId] = useState("");
  const [msg, setMsg] = useState("");
  const [updata, setUpdata] = useState(false);
  const [coachId, setCoachId] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const messagesEndRef = useRef<any>(null);
  const [activechat, setactivechat] = useState("");

 
 


  const GetMsg = (chatId: any,pageNum:any) => {
    setactivechat(chatId)
    if (chatId) {
      GetAllMsg(cookie, chatId, pageNum).then((res: any) => {
        const newHistory = Array.isArray(res.history) ? res.history : [];

        setChatData((prevChatData: any) => {
          const previousHistory = prevChatData.history || [];

          return {
            ...res,
            history: [
              ...previousHistory, // Keep existing chat history
              ...newHistory,      // Add new messages
            ],
          };
        });

        setPageLimit(res.total_page);
      });
    }
  };

  // Scroll to bottom when new messages are added
  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [chatData]);

  const fetchMsg = () => {
    
    fetchCoachByCoachee(1, cookie).then((res: any) => {
      setCoachData(res.data.history);
    });
    GetMsg(activechat,page);
  };
  const handleTicketClick = (ticket: any) => {
    setChatData([]);
    setPage(pageLimit);
    GetMsg(ticket.chatId, pageLimit);
  };
  useEffect(() => {
    fetchMsg();
  }, [chatId]);

  // const loadMoreMessages = (direction: any) => {
  //   const nextPage = direction === "next" ? page + 1 : page - 1;
  //   if (nextPage > 0 && nextPage <= pageLimit) {
  //     GetMsg(nextPage);
  //     setPage(nextPage);
  //   }
  // };
  const loadMoreMessages = () => {
    const previousPage = page + 1; 
    if (previousPage > 0) {
      GetMsg(activechat, previousPage);
      setPage(previousPage); 
    }
  };

  const getCoachId = (to: any) => {
    setCoachId(to);
    GetCoachMsg(to, cookie).then((res: any) => {
      setChatId(res.data._id);
    });
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatData]);
  const SendMsgs = () => {
    setUpdata(false);
    if (!msg.trim()) {
      notifyError("Message cannot be empty!");
      return;
    }
    SendMsg(cookie, chatId, msg).then(() => {
      setMsg("");
      setUpdata(true);
    });
    setUpdata(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      SendMsgs();
    }
  };
  useEffect(() => {
    setChatData([]); 
    if (coachId) {
      GetMsg(chatId,page);  
    }
  }, [coachId,updata]);
  return (
    <>
      <div className={style.ChatContainer}>
        <div className={style.Chatparts}>
          <div className={style.ChatpartsLeft}>
            <div className={style.Chathead}>
              <h4> Coach List </h4>
            </div>
            <div className={style.DisplaycoachContent}>
              {coachData.length > 0 ? (
                coachData.map((coach: any) => (
                  <div
                    key={coach.coachDetails._id}
                    onClick={() => {getCoachId(coach.userDetails._id);handleTicketClick(coach.coachDetails._id)}}
                    className={style.TicketDesc}
                  >
                    <img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.userDetails.profileImg}`}
                      className={style.CoachImg}
                    />
                    <div className={style.CoachDesc}>
                      <p>{coach.userDetails.name}</p>
                      <span>{coach.userDetails.email}</span>
                    </div>
                  </div>
                ))
              ) : (
                "Please Buy Any program"
              )}
            </div>
          </div>
          <div className={style.ChatpartsRight}>
            <div className={style.chapterHead}>
              {coachData.length > 0 &&
                coachData.map((coach: any) =>
                  coach.userDetails._id === coachId ? (
                    <div key={coach.userDetails._id} className={style.chattinghead}>
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.userDetails.profileImg}`}
                        className={style.CoachImg}
                      />
                      <div className={style.CoachDesce}>
                        <p>{coach.userDetails?.name}</p>
                      </div>
                    </div>
                  ) : null
                )}
            </div>
            <div
              className={`${
                chatData?.history && chatData?.history.length < 7
                  ? style.DisplayMsgContent1
                  : style.DisplayMsgContent
              }`}
            >
              {chatData?.history ? (
                chatData?.history.length > 0 ? (
                  chatData?.history.map((msg: any, index: any) => (
                    <div key={index} className={style.DisplayMsg}>
                      {msg.from === user?._id ? (
                        <div className={style.msgTextLeft} >
                          <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>
                          <p>{msg.text}</p>
                        </div>
                      ) : (
                        <div className={style.msgTextRight}>
                          <p>{msg.text}</p>
                          <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>

                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }}>No messages yet.</div>
                )
              ) : (
                <div style={{ textAlign: "center" }}>Start Chat with Coach!</div>
              )}
              <div ref={messagesEndRef} />
            </div>
            {page < pageLimit && (
              <button
                onClick={loadMoreMessages}
                className={style.LoadMoreButton}
              >
                Load More
              </button>
            )}

            {coachId && (
              <div className={style.SendMsgButton}>
                <input
                  type="text"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={SendMsgs}>
                  <SendIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatCoach;
